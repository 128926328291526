import {
  RowSelectionState,
  Table as ReactTable,
  VisibilityState,
  ColumnFiltersState,
} from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { FilteredPaginatedQuery } from 'src/service/OrgTypes'
import BackgroundLoader from '../loader/BackgroundLoader'
import Loader from '../loader/Loader'
import TableBody from './TableBody'
import TableButtons, { TableButtonProps } from './TableButtons'
import useTable, { Sorting, TableFilter, UseTableProps } from './useTable'

type TableProps<T> = Omit<UseTableProps<T>, 'data'> & {
  useDataQuery: FilteredPaginatedQuery<T>
  initialFilter?: Omit<TableFilter, 'sort'>
  tableButtons?: (
    selectedItems: T[],
  ) => Omit<TableButtonProps, 'table' | 'filters'>
  setSelectedRowsParent?: (rows: RowSelectionState) => void
  initialSelectedRowIds?: number[]
  selectable?: boolean
  adjustableHeight?: boolean
  initialColumnVisibility?: VisibilityState
  defaultFilter?: ColumnFiltersState
}

// NB! Note on export in the new table
// Export data is based on column definitions, NOT columns/rows in export files
// Specify export fields in `CustomMeta.field` if it differs from the column field used in the web view
// For legacy column definitions, use `filterDataField`

const Table = <T extends { id: string | number }>({
  useDataQuery,
  tableButtons,
  initialFilter,
  setSelectedRowsParent,
  initialSelectedRowIds,
  selectable = true,
  adjustableHeight = false,
  initialColumnVisibility,
  defaultFilter,
  ...useTableProps
}: TableProps<T>) => {
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(30)
  const [filters, setFilters] = useState<TableFilter>({})
  const [sort, setSort] = useState<Sorting>()
  const [configFetched, setConfigFetched] = useState(false)

  const {
    data: res = {
      pages: 0,
      total_items: 0,
      items: [],
    },
    isFetching,
  } = useDataQuery({
    sort,
    filters: { ...filters, ...initialFilter },
    pageSize,
    page,
    enabled: configFetched,
  })

  useEffect(() => {
    setSelectedRowsParent?.(selectedRows)
  }, [selectedRows])

  useEffect(() => {
    setSelectedRows(
      initialSelectedRowIds?.reduce((acc, b) => {
        acc[b] = true
        return acc
      }, {}) ?? {},
    )
  }, [initialSelectedRowIds])

  const selectedItems =
    useMemo(() => {
      const ids = Object.keys(selectedRows)
      return res.items?.filter((item) => ids.includes(`${item.id}`))
    }, [res, selectedRows]) ?? []

  const onRowSelection = (state: RowSelectionState) => {
    setSelectedRows(state)
  }

  const table = useTable({
    data: res.items,
    selectionState: selectedRows,
    onSelect: onRowSelection,
    selectable,
    initialColumnVisibility,
    defaultFilter,
    ...useTableProps,
  })

  useEffect(() => {
    if (!configFetched) {
      setConfigFetched(!table.tableConfigPending)
    }
  }, [table.tableConfigPending])

  useEffect(() => {
    setPage(table.page)
    setPageSize(table.pageSize)
    setFilters(table.filters)
    setSort(table.sorting)
  }, [table.filters, table.page, table.pageSize, table.sorting])

  if (table.tableConfigPending) {
    return <Loader />
  }

  return (
    <>
      {
        <TableButtons
          table={table.table as ReactTable<unknown>}
          filters={{
            ...table.filters,
            ...initialFilter,
          }}
          {...(tableButtons && { ...tableButtons(selectedItems) })}
        />
      }
      <TableBody
        table={table.table}
        pageCount={res.pages}
        totalItems={res.total_items}
        adjustableHeight={adjustableHeight}
      />
      <BackgroundLoader active={isFetching} />
    </>
  )
}

export default Table
